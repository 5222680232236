import React from "react";

const Footer: React.FC = () => {
  return (
    <footer className="bg-custom-purple text-white pb-5 relative">
      {/* Top section with overlay */}
      <div className="relative overflow-hidden px-4 md:px-0">
        {/* Overlay for this section */}
        <div className="absolute inset-0 top-[-20px] bottom-0 left-0 right-0 w-full h-full bg-black opacity-30 z-0"></div>

        {/* Content with responsive padding */}
        <div className="relative z-10 container mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 py-10 border-b border-custom-purple text-center px-4 md:px-0">
          {/* Column 1 - Introduction */}
          <div>
            <h3 className="text-lg font-bold mb-4">
              TRƯỜNG THỊNH CHEMICALS – TTC
            </h3>
            <p>
              Là nhà cung cấp giải pháp chất kết dính chuyên nghiệp, chuyên
              nghiên cứu, phát triển và cung cấp các giải pháp chất kết dính
              chuyên dụng. Từ đó, giúp khách hàng nâng cao chất lượng sản phẩm,
              tiết kiệm chi phí sản xuất trong các ngành: sản xuất bao bì, sản
              xuất đồ gỗ, sản xuất băng keo, v.v.
            </p>
          </div>

          {/* Column 2 - Customer Support */}
          <div>
            <h3 className="text-lg font-bold mb-4">HỖ TRỢ KHÁCH HÀNG</h3>
            <ul className="space-y-2">
              <li>Vì sao chọn chúng tôi?</li>
              <li>Cam kết của chúng tôi</li>
              <li>Chính sách bảo mật</li>
            </ul>
          </div>

          {/* Column 3 - Contact Information */}
          <div>
            <h3 className="text-lg font-bold mb-4">
              TRƯỜNG THỊNH CHEMICALS (TTC)
            </h3>
            <p>CÔNG TY TNHH CÔNG NGHỆ HÓA CHẤT TRƯỜNG THỊNH</p>
            <p>MST: 0317445583</p>
            <p>
              Địa chỉ: 46/7 Trung Mỹ Tây 13, phường Trung Mỹ Tây, Quận 12, TP.
              HCM
            </p>
            <p>Hotline: 090 828 79 66</p>
            <p>Email: infobox.ttc@gmail.com</p>
          </div>
        </div>
      </div>

      {/* Main footer section */}
      <div className="container mx-auto text-center px-4 md:px-0">
        <p>Copyright 2024 © Trường Thịnh Chemicals</p>
      </div>
    </footer>
  );
};

export default Footer;
