import React from "react";

const Contact: React.FC = () => {
  return (
    <section className="container mx-auto py-10 px-4 md:px-8">
      <div className="grid md:grid-cols-2 gap-8">
        {/* Gửi yêu cầu qua email */}
        <div>
          <h2 className="text-2xl font-bold text-custom-purple mb-4 text-center">
            GỬI YÊU CẦU QUA EMAIL
          </h2>
          <p className="text-gray-700 mb-6 text-center md:text-left">
            Vui lòng điền thông tin, TTC sẽ phản hồi bạn trong thời gian nhanh
            nhất. Nếu là trường hợp khẩn cấp, vui lòng gọi Hotline 090 828 79 66
          </p>
          <form className="space-y-4">
            <input
              type="text"
              placeholder="Tên của bạn (bắt buộc)"
              required
              className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-custom-purple"
            />
            <input
              type="email"
              placeholder="Email của bạn (bắt buộc)"
              required
              className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-custom-purple"
            />
            <textarea
              placeholder="Lời nhắn"
              required
              rows={4}
              className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-custom-purple"
            ></textarea>
            <button
              type="submit"
              className="w-full bg-custom-purple text-white font-bold py-2 rounded hover:bg-purple-800 transition"
            >
              Xác Nhận
            </button>
          </form>
        </div>

        {/* Thông tin liên hệ */}
        <div>
          <h2 className="text-2xl font-bold text-custom-purple mb-4 text-center">
            THÔNG TIN LIÊN HỆ
          </h2>
          <div className="text-center md:text-left">
            <p className="text-gray-700 mb-2">
              <strong>CÔNG TY TNHH CÔNG NGHỆ HÓA CHẤT TRƯỜNG THỊNH</strong>
            </p>
            <p className="text-gray-700 mb-2">
              Địa chỉ: 46/7 Trung Mỹ Tây 13, P. Trung Mỹ Tây, Q. 12, TP. HCM
            </p>
            <p className="text-gray-700 mb-2">MST: 0317445583</p>
            <p className="text-gray-700 mb-2">Hotline: 090 828 79 66</p>
            <p className="text-gray-700 mb-2">Email: infobox.ttc@gmail.com</p>
          </div>
        </div>
      </div>

      {/* Google Maps */}
      <div className="mt-10">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3918.433816189051!2d106.6122401123648!3d10.854571889254371!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752b458e9031bd%3A0x794824c3ecddf91e!2zVFLGr-G7nE5HIFRI4buKTkggQ0hFTUlDQUxT!5e0!3m2!1sen!2s!4v1730050836564!5m2!1sen!2s"
          width="100%"
          height="450"
          style={{ border: 0 }}
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Trường Thịnh Chemicals Location Map"
          className="rounded-lg shadow-lg"
        ></iframe>
      </div>
    </section>
  );
};

export default Contact;
